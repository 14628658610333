import Login from "./components/LoginPage";
import Layout from "./components/Layout";
import Verify from "./components/Verify";
import Unit from "./components/Newunit";
import User from "./components/Viewusers";
import Voucher from "./components/Voucher";
import View from "./components/Viewverification";
import Newrequest from "./components/Newrequest";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import Signup from "./components/Signup";
import RequireAuth from "./components/RequireAuth";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import EmailVerify from "./components/EmailVerify";
import Profile from "./components/profile";

const ROLES = {
  Sadmin: 3007,
  Admin: 5150,
  Editor: 1984,
  User: 2001,
  Disable: 1001,
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="createaccount" element={<Signup />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="/users/:id/verify/:token" element={<EmailVerify />} />

        {/* we want to protect these routes */}
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="/" element={<Dashboard />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="verify" element={<Verify />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="profile" element={<Profile />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="units" element={<Unit />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="voucher" element={<Voucher />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="view" element={<View />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Sadmin]} />}>
          <Route path="user" element={<User />} />
        </Route>
        <Route
          element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Sadmin]} />}
        >
          <Route path="newrequest" element={<Newrequest />} />
        </Route>
        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
