import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import useAuth from "../../hooks/useAuth";
import PaystackPop from "@paystack/inline-js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function LeftPart() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const email = auth?.nuser?.email;
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  // Rest of the component
  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setMsg("");
    setIsLoading(true);

    if (password !== confirmPassword) {
      setIsInvalid(true);
      setError("Passwords do not match");
      setIsLoading(false);
      return;
    }

    if (!isPasswordValid(password)) {
      setIsInvalid(true);
      setError("Password does not meet the required standards");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post("/changePassword", {
        email,
        pwd: password,
      });
      setMsg("Password successfully changed");
      // Clear the form
      setPassword("");
      setConfirmPassword("");
      setIsInvalid(false);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isPasswordValid = (pwd) => {
    // Adjust the regular expression according to your password standards
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/;

    return regex.test(pwd);
  };

  return (
    <div className=" col-span-2 border-r border-gray-200 items-start justify-start flex flex-col w-full ">
      {/* top section*/}
      <div className="w-full items-start justify-start flex flex-col px-12 pt-12 pb-6">
        <h1 className="font-bold text-xl xl:text-2xl pb-2 "> Profile </h1>
        <p className="text-md text-gray-800">Change password</p>
        <form className="space-y-6 w-full mt-4" onSubmit={handleSubmit}>
          <div>
            <label className="block">
              <span className="text-gray-700">New Pasword</span>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Password"
                required
              />
            </label>
          </div>
          <div>
            <label className="block">
              <span className="text-gray-700">Confirm Pasword</span>
              <input
                id="confirm-password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Password"
                required
              />
            </label>
          </div>
          <div>
            {error && (
              <div className={"w-full px-2 " + styles.error_msg}>{error}</div>
            )}
            {msg && (
              <div className={"w-full px-2 " + styles.success_msg}>{msg}</div>
            )}
          </div>
          <div className="flex items-center">
            {isLoading ? ( // Conditionally render loading indicator
              <button
                disabled
                className="h-12 px-6 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none flex-1 mr-1"
              >
                Changing Password ...
              </button>
            ) : (
              <button
                type="submit"
                className="h-12 px-6 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none flex-1 mr-1"
              >
                Change Password
              </button>
            )}
          </div>
        </form>
      </div>
      {/* bottom section*/}
    </div>
  );
}

export default LeftPart;
