import { CashIcon, CreditCardIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from "jquery";
import "datatables.net";

function RightPartu() {
  const { auth, au, uu, setAu, setUu, all, uw, setUw, datas2, setDatas2 } =
    useAuth();
  const axiosPrivate = useAxiosPrivate();
  const email = auth?.nuser?.email;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(`/voucher/${email}`);
        const dataArray = Object.values(response.data);
        if (Array.isArray(dataArray)) {
          const formattedData = dataArray.map((item) => ({
            ...item,
          }));
          setDatas2(formattedData);
        } else {
          console.log("Response data is not an array");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [email]);

  useEffect(() => {
    if (!loading) {
      $(document).ready(function () {
        $("#user-table").DataTable();
      });
    }
  }, [loading]);

  const columns = [
    { label: "Voucher", field: "voucher" },
    {
      label: "Usage",
      field: "usage",
      render: (data) => {
        if (data === 1) {
          return "Available";
        } else if (data === 2) {
          return "Used";
        } else {
          return "";
        }
      },
    },
  ];

  return (
    <div className="col-span-3 items-start justify-start flex flex-col w-full pt-12 pb-6 my-4 px-4">
      {/* top section */}

      <div className="w-full mt-4">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="table-wrapper">
            <table id="user-table">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.field}>{column.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {datas2.map((data) => (
                  <tr key={data.voucher}>
                    <td>{data.voucher}</td>
                    <td>{columns[1].render(data.usage)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default RightPartu;
