import React, { useState } from "react";
import styles from "./styles.module.css";
import api from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import PaystackPop from "@paystack/inline-js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function RightPartv() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const identityOptions = [
    { label: "Choose one", value: "" },
    { label: "Virtual National Identification Number (vNIN)", value: "vnin" },
  ];

  const downloadPDF = () => {
    const node = document.getElementById("my-node");
    html2canvas(node).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // ID card size
      const pdfWidth = 3.375; // inches
      const pdfHeight = 2.125; // inches

      // scale factor calculation to fit the content width to ID card width
      const scaleFactor = (pdfWidth * 96) / canvas.width;

      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "in", // units in inches
        format: [pdfWidth, pdfHeight], // format based on ID card size
      });

      // scale height to maintain the aspect ratio
      const scaledHeight = (canvas.height * scaleFactor) / 96;

      // calculate the vertical position to center the content in the card
      const yOffset = (pdfHeight - scaledHeight) / 2;

      pdf.addImage(imgData, "PNG", 0, yOffset, pdfWidth, scaledHeight);
      pdf.save("download.pdf");
    });
  };

  const email = auth?.nuser?.email;
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    idno: "",
    idtype: "",
    email: email,
    reference: "",
    remark: "remark",
    preference: "",
  });
  const [dataurl, setDataurl] = useState("");

  const [img, setImg] = useState("");

  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [ndata, setNdata] = useState({
    firstName: "",
    surname: "",
    middleName: "",
    dateOfBirth: "",
    userid: "",
    gender: "",
    trustedNumber: "",
    txid: "",
    ts: "",
    vNIN: "",
    agentID: "",
    photograph: "",
    barcode: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [error, setError] = useState("");

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleClick = () => {
    setPaymentStatus("pay");
  };

  const unitClick = () => {
    setPaymentStatus("unit");
  };
  const [isBusy, setIsBusy] = useState(false); // Add isLoading state

  const handleformSubmit = () => {
    setFormSubmitted(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Set isLoading to true when form is submitted
    setError("");

    const generateRandomString = () => {
      const length = 6; // set the desired length of the string
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // set the allowed characters for the string
      let result = ""; // initialize an empty string

      // loop through the length and add a random character from the allowed characters to the result string
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }

      // return the result string with the word "unit" appended to the end
      return result + "unit";
    };
    if (data.preference !== "") {
      await axiosPrivate.put("/vadmin", {
        reference: data.preference,
        verified: true,
      });
    }
    if (paymentStatus === "pay") {
      try {
        const paystack = new PaystackPop();

        paystack.newTransaction({
          key: "pk_test_22a51a8148647e14ac7dccda4b605db35b188715",
          amount: 1000 * 100,
          email: email,
          onSuccess: async (transaction) => {
            try {
              setIsLoading(true);
              const url = "/verify";
              data.reference = transaction.reference;
              //console.log(data);
              //console.log(transaction.reference);
              const { data: res } = await axiosPrivate.post(url, data);
              setNdata(res.message);
              const uri = `data:image/png;base64,${res.message.barcode}`;
              setDataurl(uri);
              const im = `data:image/png;base64,${res.message.photograph}`;
              setImg(im);
              setData({
                firstname: "",
                lastname: "",
                phone: "",
                idno: "",
                idtype: "",
                email: email,
                reference: "",
                remark: "remark",
                preference: "",
              });

              //console.log(data);
              setFormSubmitted(true);
            } catch (error) {
              if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
              ) {
                setError(error.response.data.message);
              }
            } finally {
              setIsLoading(false); // Set isLoading back to false after response is received
            }
          },
          onCancel() {
            alert("You have canceled the transaction");
          },
        });
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      } finally {
        setIsLoading(false); // Set isLoading back to false after response is received
      }
    }
    if (paymentStatus === "unit") {
      //console.log(paymentStatus);
      try {
        const result = await axiosPrivate.post("/useunit", {
          email: email,
        });
        try {
          setIsBusy(true);
          const randomString = generateRandomString();
          const url = "/verify";
          data.reference = randomString;
          //console.log(data);
          //console.log(transaction.reference);
          const { data: res } = await axiosPrivate.post(url, data);
          setNdata(res.message);
          const uri = `data:image/png;base64,${res.message.barcode}`;
          setDataurl(uri);
          const im = `data:image/png;base64,${res.message.photograph}`;
          setImg(im);
          setData({
            firstname: "",
            lastname: "",
            phone: "",
            idno: "",
            idtype: "",
            email: email,
            reference: "",
            remark: "remark",
            preference: "",
          });

          //console.log(data);
          setFormSubmitted(true);
        } catch (error) {
          if (
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
          ) {
            setError(error.response.data.message);
          }
        } finally {
          setIsBusy(false); // Set isLoading back to false after response is received
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      } finally {
        setIsBusy(false); // Set isLoading back to false after response is received
      }
    }
  };

  return (
    <div className="col-span-3 items-start justify-start w-full pt-12 pb-6">
      {/* top section */}
      {!formSubmitted ? (
        <>
          {" "}
          <h1 className="font-bold text-xl xl:text-2xl pb-2  px-4 w-full">
            Provide information about who you want verified:
          </h1>
          <form className="space-y-6 w-full px-6" onSubmit={handleSubmit}>
            <div>
              <label className="block">
                <span className="text-gray-700">FIRST NAME</span>
                <input
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  value={data.firstname}
                  id="firstname"
                  className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="First Name"
                  required
                />
              </label>
            </div>
            <div>
              <label className="block">
                <span className="text-gray-700">LAST NAME</span>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  onChange={handleChange}
                  value={data.lastname}
                  className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="Last Name"
                  required
                />
              </label>
            </div>
            <div>
              <label className="block">
                <span className="text-gray-700">PHONE</span>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  onChange={handleChange}
                  value={data.phone}
                  className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                  placeholder="Phone"
                />
              </label>
            </div>
            <div>
              <label className="block">
                <span className="text-gray-700">IDENTITY TYPE</span>
                <select
                  name="idtype"
                  id="idtype"
                  onChange={handleChange}
                  value={data.idtype}
                  className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                >
                  {identityOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label className="block">
                <span className="text-gray-700">IDENTIFICATION NUMBER</span>
                <input
                  type="text"
                  name="idno"
                  id="idno"
                  onChange={handleChange}
                  value={data.idno}
                  className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                  placeholder="Identification Number"
                />
              </label>
            </div>
            <div>
              <label className="block">
                <span className="text-gray-700">PAYMENT REFERENCE</span>
                <input
                  type="text"
                  name="preference"
                  id="preference"
                  onChange={handleChange}
                  value={data.preference}
                  className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="Payment Reference"
                />
                <p className="text-gray-500 text-sm mt-1">
                  Please leave empty if payment reference is not available
                </p>
              </label>
            </div>

            {data.idtype === "vnin" && (
              <div className="text-green-700 font-bold">
                Payment with Paystack cost ₦1000 for each vNIN verification
              </div>
            )}
            <div>
              {error && (
                <div className={"w-full px-2 " + styles.error_msg}>{error}</div>
              )}
            </div>
            <div className="flex items-center">
              {isBusy ? (
                <button
                  disabled
                  type="button"
                  className="h-12 px-6 text-white bg-green-500 rounded-lg hover:bg-green-600 focus:outline-none flex-1 ml-1"
                >
                  Verifying ...
                </button>
              ) : (
                <button
                  onClick={unitClick}
                  type="submit"
                  className="h-12 px-6 text-white bg-green-500 rounded-lg hover:bg-green-600 focus:outline-none flex-1 ml-1"
                >
                  Verify with Unit
                </button>
              )}
            </div>
          </form>
        </>
      ) : (
        <div
          id="my-node"
          className="md:flex items-start justify-start w-full lg:space-y-0 space-y-4 lg:space-x-4 px-12 flex flex-col"
        >
          <div className="bg-white flex flex-col items-center justify-start px-4  w-[600px] h-[350px] shadow-md">
            <div className="flex items-center justify-center w-full">
              <div className="flex items-center justify-center w-24 h-24 mr-4">
                <img
                  src="nimc.png"
                  alt="Logo"
                  className="w-auto h-auto max-w-24 max-h-24"
                />
              </div>
              <div>
                <h2 className="text-xl font-bold">
                  FEDERAL REPUBLIC OF NIGERIA
                </h2>
                <p className="text-sm">
              Verified at National Identity Management Commission
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center w-full">
              <div className="id-card-photo w-48 h-48 overflow-hidden mx-6">
                <img
                  src={img}
                  alt="Photo"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex flex-col items-start justify-between space-y-2">
                <p className="text-base font-semibold">
                  Surname/Nom : <br /> {ndata.surname}
                </p>
                <p className="text-base font-semibold">
                  Given Names/Prenoms :<br /> {ndata.firstName},{" "}
                  {ndata.middleName}
                </p>
                <p className="text-base font-semibold">
                  Date of Birth : <br /> {ndata.dateOfBirth}
                </p>
                <p className="text-base font-semibold">
                  Gender : {ndata.gender}
                </p>
              </div>
              <div className="flex flex-col items-center justify-center ml-6">
                <img src={dataurl} alt="QR Code" className="w-48 h-48" />
              </div>
            </div>
          </div>
        </div>
      )}
      {formSubmitted ? (
        <div className="border-t border-gray-200 w-full my-4 pt-4 flex flex-col justify-center items-center">
          <button onClick={downloadPDF}>Download PDF</button>
          <button
            onClick={handleformSubmit}
            type="button"
            className="w-60 h-12 px-6 text-white bg-green-500 rounded-lg hover:bg-green-600 focus:outline-none ml-1"
          >
            Back to verification
          </button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default RightPartv;
