import React from "react";
import Header from "./header/Header";
import NavBar from "./navbar/NavBar";
import LeftPart from "./leftPart/LeftPartnr";

const Newrequest = () => {
  return (
    <div className="App overflow-y-hidden ">
      <Header />
      <div className="w-full min-h-[90vh] grid grid-cols-12">
        <NavBar />
        <div className="grid grid-cols-1 xl:grid-cols-5 col-span-10 w-full">
          <LeftPart />
        </div>
      </div>
    </div>
  );
};

export default Newrequest;
