import { CashIcon, CreditCardIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
function RightPart() {
  const {
    auth,
    au,
    uu,
    setAu,
    setUu,
    all,
    uw,
    setUw,
    tau,
    tuu,
    tav,
    tuv,
    settAu,
    settUu,
    settAv,
    settuv,
    tcu,
    settcu,
    tvoucher,
    setTvoucher,
    uvoucher,
    setUvoucher,
  } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const email = auth?.nuser?.email;
  const [isLoading, setIsLoading] = useState(true);

  const roles = auth?.roles;
  const hasAdminRole = roles.includes(5150);
  const hasSuperAdminRole = roles.includes(3007);
  const hasModeratorRole = roles.includes(1984);
  const hasUserRole = roles.includes(2001);

  const summary = async () => {
    const response = await axiosPrivate.get(`/useunit/${email}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });

    // Convert the response data to a JSON object
    const result = response.data;
    setAu(result.data.au);
    setUu(result.data.uu);
    setUw(result.data.au * 1000);

    const respon = await axiosPrivate.get(`/vouchercount/${email}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    //console.log(respon.data);

    setTvoucher(respon.data.tvoucher);
    setUvoucher(respon.data.utvoucher);

    //console.log(all.au);
    if (hasSuperAdminRole) {
      const respons = await axiosPrivate.get(`/useunit`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      //console.log(respons.data);

      settAu(respons.data.tau);
      settUu(respons.data.tuu);

      const respon = await axiosPrivate.get(`/vouchercount`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      //console.log(respon.data);

      settAv(respon.data.tvoucher);
      settuv(respon.data.utvoucher);

      const respo = await axiosPrivate.get(`/userscount`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      //console.log(respo.data);

      settcu(respo.data.totalUserCount);
    }
    setIsLoading(false);
    // Update the state variables with the fetched data
  };

  useEffect(() => {
    summary();
  }, []);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (au !== null) {
      all.au = au;
      //console.log(all.au);
    }
  }, [au]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (uu !== null) {
      all.uu = uu;
    }
  }, [au]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (uw !== null) {
      all.uw = uw;
    }
  }, [uw]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (au !== null) {
      all.tau = tau;
      //console.log(all.au);
    }
  }, [tau]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (tuu !== null) {
      all.tuu = tuu;
    }
  }, [tuu]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (tav !== null) {
      all.tav = tav;
    }
  }, [tav]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (tuv !== null) {
      all.tuv = tuv;
    }
  }, [tuv]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (tcu !== null) {
      all.tcu = tcu;
    }
  }, [tcu]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (tvoucher !== null) {
      all.tvoucher = tvoucher;
    }
  }, [tvoucher]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (uvoucher !== null) {
      all.uvoucher = uvoucher;
    }
  }, [uvoucher]);

  return (
    <>
      <div className="col-span-3 items-start justify-start flex flex-col w-full pt-12 pb-6">
        {/* top section */}
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className="md:flex items-center justify-center w-full lg:space-y-0 space-y-4  lg:space-x-4  px-12">
              <div className="space-y-6 w-full items-center justify-center flex flex-col ">
                <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
                  <CashIcon className="w-8 h-8 stroke-1 " />
                </span>
                <span className="items-center justify-center flex flex-col">
                  <h2> Available Unit</h2>
                  <h2 className="font-bold text-xl">{au} </h2>
                </span>
              </div>
              {/* duplicate above ☝ */}
              <div className="space-y-6 w-full items-center justify-center flex flex-col ">
                <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
                  <CreditCardIcon className="w-8 h-8 stroke-1" />
                </span>
                <span className="items-center justify-center flex flex-col">
                  <h2> Used Unit</h2>
                  <h2 className="font-bold text-xl">{uu} </h2>
                </span>
              </div>

              <div className="space-y-6 w-full items-center justify-center flex flex-col ">
                <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
                  <CreditCardIcon className="w-8 h-8 stroke-1" />
                </span>
                <span className="items-center justify-center flex flex-col">
                  <h2> Available Voucher</h2>
                  <h2 className="font-bold text-xl">{tvoucher} </h2>
                </span>
              </div>

              <div className="space-y-6 w-full items-center justify-center flex flex-col ">
                <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
                  <CreditCardIcon className="w-8 h-8 stroke-1" />
                </span>
                <span className="items-center justify-center flex flex-col">
                  <h2> Used Voucher</h2>
                  <h2 className="font-bold text-xl">{uvoucher} </h2>
                </span>
              </div>
            </div>
            {hasSuperAdminRole && (
              <>
                <div className="md:flex items-center justify-center w-full lg:space-y-0 space-y-4  lg:space-x-4  px-12 py-8">
                  <div className="space-y-6 w-full items-center justify-center flex flex-col ">
                    <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
                      <CashIcon className="w-8 h-8 stroke-1 " />
                    </span>
                    <span className="items-center justify-center flex flex-col">
                      <h2> Total Available Unit</h2>
                      <h2 className="font-bold text-xl">{tau} </h2>
                    </span>
                  </div>

                  <div className="space-y-6 w-full items-center justify-center flex flex-col ">
                    <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
                      <CashIcon className="w-8 h-8 stroke-1 " />
                    </span>
                    <span className="items-center justify-center flex flex-col">
                      <h2> Total Used Unit</h2>
                      <h2 className="font-bold text-xl">{tuu} </h2>
                    </span>
                  </div>

                  <div className="space-y-6 w-full items-center justify-center flex flex-col ">
                    <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
                      <CashIcon className="w-8 h-8 stroke-1 " />
                    </span>
                    <span className="items-center justify-center flex flex-col">
                      <h2> Total Available Voucher</h2>
                      <h2 className="font-bold text-xl">{tav} </h2>
                    </span>
                  </div>
                </div>
                <div className="md:flex items-center justify-center w-full lg:space-y-0 space-y-4 lg:space-x-4 px-12 py-8">
                  <div className="space-y-6 w-full items-center justify-center flex flex-col">
                    <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
                      <CashIcon className="w-8 h-8 stroke-1" />
                    </span>
                    <span className="items-center justify-center flex flex-col">
                      <h2>Total Used Voucher</h2>
                      <h2 className="font-bold text-xl">{tuv}</h2>
                    </span>
                  </div>

                  <div className="space-y-6 w-full items-center justify-center flex flex-col">
                    <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
                      <CashIcon className="w-8 h-8 stroke-1" />
                    </span>
                    <span className="items-center justify-center flex flex-col">
                      <h2>Total Users</h2>
                      <h2 className="font-bold text-xl">{tcu}</h2>
                    </span>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {/* chart */}

        {/* bottom part */}
      </div>
    </>
  );
}

export default RightPart;
