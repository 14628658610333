import React from "react";
import { navLinks } from "./utils/NavDB";
import { useRecoilState } from "recoil";
import { activeNavItemState } from "../../atoms/ActiveNavBarAtom";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function NavBar() {
  const { auth } = useAuth();

  const roles = auth?.roles;
  const hasAdminRole = roles.includes(5150);
  const hasSuperAdminRole = roles.includes(3007);
  const hasModeratorRole = roles.includes(1984);
  const hasUserRole = roles.includes(2001);

  const roleLinkMap = {
    hasAdminRole: [
      "Dashboard",
      "Verify",
      "Units",
      "Profile",
      "View",
      "Request",
    ],
    hasModeratorRole: ["Dashboard", "Voucher", "Profile"],
    hasUserRole: ["Dashboard", "Verify", "View", "Units", "Profile"],
  };

  const canViewLink = (linkTitle) => {
    if (hasSuperAdminRole) return true;
    if (hasAdminRole && roleLinkMap.hasAdminRole.includes(linkTitle))
      return true;
    if (hasModeratorRole && roleLinkMap.hasModeratorRole.includes(linkTitle))
      return true;
    if (
      !hasAdminRole &&
      !hasSuperAdminRole &&
      !hasModeratorRole &&
      hasUserRole &&
      roleLinkMap.hasUserRole.includes(linkTitle)
    )
      return true;
    return false;
  };
  return (
    <nav className="col-span-2 border-r border-gray-200 min-h-[90vh] w-[80px] xl:w-[250px] pt-8 px-1 flex flex-col items-start justify-between">
      <div className="space-y-8 w-full ">
        {navLinks.map(
          (link) =>
            canViewLink(link.title) && <NavItem link={link} key={link.id} />
        )}
      </div>
      <div className="xl:flex flex-col hidden  items-center justify-center space-y-4 px-4 py-4 ">
        <h1 className="text-xl w-full font-medium">
          Save Your <br /> Verification cost NOW!
        </h1>
        <p> Get a lower rate of verifying by purchasing units </p>
        <NavLink
          to="/units"
          className="flex justify-center items-center w-full py-2 px-3 bg-black text-white"
        >
          Purchase Unit
        </NavLink>
      </div>
    </nav>
  );
}

function NavItem({ link }) {
  const [activeNav, setActiveNav] = useRecoilState(activeNavItemState);

  return (
    <NavLink
      to={link.link}
      onClick={() => setActiveNav(link.id)}
      key={link.id}
      className={`w-full flex items-center justify-start space-x-8 px-5 cursor-pointer
       group hover:border-gray-900 border-l-4 border-transparent ${
         activeNav === link.id && "border-gray-900 "
       } `}
    >
      <span> {link.icon}</span>
      <h1
        className={`text-gray-600 group-hover:text-black xl:flex hidden ${
          activeNav === link.id && "text-black "
        }} `}
      >
        {link.title}
      </h1>
    </NavLink>
  );
}

export default NavBar;
