import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function LeftPart() {
  const { auth, au, uu, setAu, setUu, all, uw, setUw } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const email = auth?.nuser?.email;
  const [isLoading, setIsLoading] = useState(true);

  const summary = async () => {
    const response = await axiosPrivate.get(`/useunit/${email}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });

    // Convert the response data to a JSON object
    const result = response.data;
    setAu(result.data.au);
    setUu(result.data.uu);
    setUw(result.data.au * 950);

    //console.log(all.au);
    setIsLoading(false);
    // Update the state variables with the fetched data
  };

  useEffect(() => {
    summary();
  }, []);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (au !== null) {
      all.au = au;
      //console.log(all.au);
    }
  }, [au]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (uu !== null) {
      all.uu = uu;
    }
  }, [au]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (uw !== null) {
      all.uw = uw;
    }
  }, [uw]);

  return (
    <div className=" col-span-2 border-r border-gray-200 items-start justify-start flex flex-col w-full ">
      {/* top section*/}
      <div className="w-full items-start justify-start flex flex-col px-12 pt-12 pb-6">
        <h1 className="font-bold text-xl xl:text-2xl pb-2 "> Dashboard </h1>
        <p className="text-md text-gray-800">Surmmary Information</p>
        <div className="items-start justify-start flex flex-col px-6 pt-8 pb-4 bg-[#89F8B7] mt-6 w-full ">
          <h1>
            {" "}
            {auth?.nuser?.firstName} {auth?.nuser?.lastName}{" "}
          </h1>
          <h1 className=" text-3xl xl:text-5xl font-bold py-6 ">{au} Unit/s</h1>
        </div>
        <div className="bg-black py-4 px-8 w-full items-start justify-between flex shadow-2xl shadow-emerald-200 ">
          <span className="flex flex-col items-start justify-start text-white">
            <h1> Email Adddress</h1>
            <h3> {auth?.nuser?.email} </h3>
          </span>
          <span className="flex flex-col items-start justify-start text-white">
           &nbsp;
          </span>
        </div>
      </div>
      {/* bottom section*/}
    </div>
  );
}

export default LeftPart;
