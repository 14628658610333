import React, { useState, useEffect } from "react";
import api from "../api/axios";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import useAuth from "../hooks/useAuth";

const userRole = {
  Sadmin: 3007,
  Admin: 5150,
  Editor: 1984,
  User: 2001,
  Disable: 1001,
};

function LoginPage() {
  const { setAuth, auth, navigate } = useAuth();

  const [data, setData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const [loading, setLoading] = useState(false);

  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    localStorage.setItem("newauth", JSON.stringify(auth));
    return () => {
      setIsMounted(false);
    };
  }, [auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setLoading(true);
      const url = "/auth";
      const { data: res } = await api.post(url, data);
      const accessToken = res?.accessToken;
      const roles = res?.roles;
      const nuser = res?.result;
      const user = data.email;

      if (roles.includes(userRole.Disable)) {
        setError("This user is disabled");
        setLoading(false);
        return; // Return early if the user is disabled
      }

      setAuth({ user, nuser, roles, accessToken });
      setData({ email: "", password: "" });
      if (roles.includes(userRole.User)) {
        navigate("/");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setLoading(false);
        setError(error.response.data.message);
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const today = new Date();
  const year = today.getFullYear();

  return (
    <section className="flex flex-col md:flex-row h-screen items-center">
      <div className="bg-blue-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
        <img
          src="background.png"
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
        <div className="w-full h-100">
          <h1 className="text-xl font-bold">
            <img src="logo.png" width="200" height="100" alt="Logo" />
          </h1>
          <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
            Log in to Verify
          </h1>

          <form className="mt-6" onSubmit={handleSubmit}>
            <div>
              <label className="block text-gray-700">Email Address</label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                value={data.email}
                placeholder="Enter Email Address"
                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                autoFocus
                required
              />
            </div>

            <div className="mt-4">
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                value={data.password}
                placeholder="Enter Password"
                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                required
              />
            </div>

            <div className="text-right mt-2">
              <a
                href="#"
                className="text-sm font-semibold text-gray-700 hover:text-blue-700 focus:text-blue-700"
              >
                Forgot Password?
              </a>
            </div>

            {error && (
              <div className={"w-full px-2 " + styles.error_msg}>{error}</div>
            )}

            <button
              type="submit"
              className="w-full block bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
              disabled={loading}
            >
              {loading ? "Loading..." : "Log In"}
            </button>
          </form>

          <hr className="my-6 border-gray-300 w-full" />

          <p className="mt-8">
            Need an account?
            <Link
              to="/createaccount"
              className="text-blue-500 hover:text-blue-700 font-semibold"
            >
              Create an account
            </Link>
          </p>

          <p className="text-sm text-gray-500 mt-12">
            &copy; {year} Ficoven - All Rights Reserved.
          </p>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
