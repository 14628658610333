import { CashIcon, CreditCardIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
function RightPartu() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const id = auth?.nuser?._id;
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleChange = () => {};

  const fetchData = async () => {
    const response = await axiosPrivate.get(`/users/${id}`);
    setFormData(response.data);
    //console.log(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="col-span-3 items-start justify-start flex flex-col w-full pt-12 pb-6">
      {/* top section */}
      <div className="space-y-6 w-full px-4 flex flex-col ">
        <h1 className="font-bold text-xl xl:text-2xl pb-2 ">
          {" "}
          Personal Information
        </h1>
        <form action="">
          <div>
            <label className="block">
              <span className="text-gray-700">FIRST NAME</span>
              <input
                type="text"
                name="firstname"
                value={formData.firstName}
                onChange={handleChange}
                disabled
                className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="First Name"
              />
            </label>
          </div>
          <div>
            <label className="block">
              <span className="text-gray-700">LAST NAME</span>
              <input
                type="text"
                name="lastname"
                value={formData.lastName}
                onChange={handleChange}
                disabled
                className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Last Name"
                required
              />
            </label>
          </div>
          <div>
            <label className="block">
              <span className="text-gray-700">Email Address</span>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                disabled
                className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                required
                placeholder="Email"
              />
            </label>
          </div>
        </form>
      </div>
      {/* chart */}

      {/* bottom part */}
    </div>
  );
}

export default RightPartu;
