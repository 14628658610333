import {
  HomeIcon,
  ShieldCheckIcon,
  EyeIcon,
  PuzzleIcon,
  ClipboardListIcon,
  UserGroupIcon,
  DocumentAddIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";

export const navLinks = [
  {
    id: 0,
    title: "Dashboard",
    link: "/",
    icon: <HomeIcon className="nav-icon" />,
  },
  {
    id: 1,
    title: "Verify",
    link: "/verify",
    icon: <ShieldCheckIcon className="nav-icon" />,
  },
  {
    id: 2,
    title: "View",
    link: "/view",
    icon: <EyeIcon className="nav-icon" />,
  },
  {
    id: 3,
    title: "Units",
    link: "/units",
    icon: <PuzzleIcon className="nav-icon" />,
  },
  {
    id: 4,
    title: "Voucher",
    link: "/voucher",
    icon: <ClipboardListIcon className="nav-icon" />,
  },
  {
    id: 5,
    title: "Users",
    link: "/user",
    icon: <UserGroupIcon className="nav-icon" />,
  },
  {
    id: 6,
    title: "Request",
    link: "/newrequest",
    icon: <DocumentAddIcon className="nav-icon" />,
  },
  {
    id: 7,
    title: "Profile",
    link: "/profile",
    icon: <UserCircleIcon className="nav-icon" />,
  },
];
