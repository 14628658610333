import { CashIcon, CreditCardIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from "jquery";
import "datatables.net";

function RightPartu() {
  const { auth, au, uu, setAu, setUu, all, uw, setUw } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const email = auth?.nuser?.email;
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      $(document).ready(function () {
        $("#user-table").DataTable();
      });
    }
  }, [loading]);

  const summary = async () => {
    const response = await axiosPrivate.get(`/useunit/${email}`);

    // Convert the response data to a JSON object
    const result = response.data;
    setAu(result.data.au);
    setUu(result.data.uu);
    setUw(result.data.au * 10);

    //console.log(all.au);
    setIsLoading(false);
    // Update the state variables with the fetched data
  };

  useEffect(() => {
    summary();
  }, []);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (au !== null) {
      all.au = au;
      //console.log(all.au);
    }
  }, [au]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (uu !== null) {
      all.uu = uu;
    }
  }, [au]);

  useEffect(() => {
    // This code will run whenever the value of 'au' changes
    if (uw !== null) {
      all.uw = uw;
    }
  }, [uw]);

  return (
    <div className="col-span-3 items-start justify-start flex flex-col w-full pt-12 pb-6">
      {/* top section */}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="md:flex items-center justify-center w-full lg:space-y-0 space-y-4  lg:space-x-4  px-12">
          <div className="space-y-6 w-full items-center justify-center flex flex-col ">
            <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
              <CashIcon className="w-8 h-8 stroke-1 " />
            </span>
            <span className="items-center justify-center flex flex-col">
              <h2> Available Unit</h2>
              <h2 className="font-bold text-xl">{au} </h2>
            </span>
          </div>
          {/* duplicate above ☝ */}
          <div className="space-y-6 w-full items-center justify-center flex flex-col ">
            <span className="py-4 px-4 rounded-full shadow-lg shadow-gray-300 items-center justify-center flex">
              <CreditCardIcon className="w-8 h-8 stroke-1" />
            </span>
            <span className="items-center justify-center flex flex-col">
              <h2> Used Unit</h2>
              <h2 className="font-bold text-xl">{uu} </h2>
            </span>
          </div>
          <div className="bg-[#BFFA00] pt-6 items-center justify-between flex flex-col w-full">
            <span className="items-center justify-center flex flex-col w-full py-6">
              <h3> Unit Worth </h3>
              <h1 className="text-black font-bold text-xl 2xl:text-3xl">
                ${uw.toLocaleString()}
              </h1>
            </span>
            <div className="bg-black items-center justify-center flex text-white w-full py-3 ">
              <h1> &nbsp;</h1>
            </div>
          </div>
        </div>
      )}
      <div className=" border-t border-gray-200 w-full my-4 px-4 "></div>
    </div>
  );
}

export default RightPartu;
