import React from "react";

function LeftPart() {
  return (
    <div className=" col-span-2 min-h-[90vh] border-r border-gray-200 items-start justify-start flex flex-col w-full ">
      {/* top section*/}
      <div className="w-full items-start justify-start flex flex-col px-12 pt-12 pb-6">
        <h1 className="font-bold text-xl xl:text-2xl pb-2 "> Verification </h1>
        <p className="text-md font-bold text-red-500">NOTICE</p>
        <p className="text-2xl font-bold pt-5">
          kindly note that the National Identity Management Commission (NIMC)
          has stopped the use of actual 11-digit NINs for verifications, in a
          bid to improve data privacy. Please generate a virtual NIN (vNIN) to
          continue your NIN verification
        </p>
        <div className="pt-5">
          <h4 className="text-2xl pb-3">Generating a vNIN</h4>
          <p>There are two ways to generate a virtual NIN:</p>
        </div>
        <div className="pt-5 text-2xl flex">
          <svg width="41" height="40" fill="none">
            <g
              stroke="#2C3E50"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.75"
              clip-path="url(#ussdhint_svg__a)"
            >
              <path d="M26.666 6.667H13.333c-.92 0-1.667.746-1.667 1.666v23.334c0 .92.747 1.666 1.667 1.666h13.333c.921 0 1.667-.746 1.667-1.666V8.333c0-.92-.746-1.666-1.666-1.666zm-8.332 1.666h3.333m-1.667 20v.017"></path>
            </g>
            <path fill="#fff" d="M20 15h21v10H20z"></path>
            <path
              fill="#262626"
              d="M22.29 17.682l-.137 1.305 1.313-.372.102.728-1.227.11.797 1.062-.663.359-.578-1.145-.516 1.142-.69-.356.789-1.063-1.22-.113.116-.724 1.289.372-.137-1.305h.762zM26.511 23h-.806v-3.216c0-.107 0-.214.003-.322l.007-.314c.004-.103.01-.2.017-.294-.048.052-.107.11-.178.17a11.13 11.13 0 01-.219.192l-.574.458-.403-.509 1.483-1.162h.67V23zm5.028 0h-3.384v-.605l1.296-1.313c.25-.255.457-.475.618-.66.162-.186.282-.363.36-.529.079-.166.119-.347.119-.543 0-.242-.07-.424-.209-.547-.139-.126-.322-.188-.55-.188-.216 0-.417.043-.602.13a2.795 2.795 0 00-.567.362l-.44-.53c.138-.118.286-.227.444-.325a2.289 2.289 0 011.203-.321c.316 0 .59.057.82.171a1.237 1.237 0 01.718 1.169c0 .269-.054.517-.16.745-.108.228-.26.454-.459.677-.198.22-.434.461-.707.721l-.865.844v.038h2.365V23zm3.814-3.855c0 .225-.045.421-.136.587a1.158 1.158 0 01-.376.41c-.157.105-.34.18-.547.226v.024c.399.05.7.175.902.376.205.2.308.466.308.796 0 .29-.07.549-.209.776a1.4 1.4 0 01-.639.534c-.287.13-.656.194-1.107.194a4.06 4.06 0 01-.755-.065 2.791 2.791 0 01-.657-.208v-.714a3.24 3.24 0 001.357.328c.417 0 .715-.078.892-.233.178-.157.267-.373.267-.65a.667.667 0 00-.14-.437c-.091-.114-.234-.199-.428-.256a2.66 2.66 0 00-.741-.085h-.441v-.646h.444c.29 0 .522-.035.697-.103a.767.767 0 00.38-.29.774.774 0 00.12-.431c0-.214-.07-.38-.209-.5-.139-.12-.345-.18-.619-.18-.168 0-.322.019-.461.058a2.085 2.085 0 00-.38.147 3.664 3.664 0 00-.331.191l-.386-.557c.189-.141.414-.26.676-.359a2.61 2.61 0 01.916-.147c.513 0 .908.11 1.187.332.278.218.416.512.416.881zm3.883.875l-.191.977h.923v.57h-1.033L38.66 23h-.609l.28-1.432h-.854L37.206 23h-.595l.264-1.432h-.855v-.57h.964l.195-.979h-.906v-.57h1.008l.274-1.443h.608l-.273 1.443h.861l.277-1.443h.591l-.273 1.443h.861v.57h-.97zm-1.65.977h.854l.195-.977h-.858l-.192.977z"
            ></path>
            <defs>
              <clipPath id="ussdhint_svg__a">
                <path fill="#fff" d="M0 0h40v40H0z"></path>
              </clipPath>
            </defs>
          </svg>{" "}
          <h4>Via USSD</h4>
        </div>
        <ul className="pt-5">
          <li className="pl-20 pt-3">
            To generate a Virtual NIN via USSD, dial <br />
            <span className="text-green-700">*346*3*Your NIN*931095#</span>
          </li>
          <li className="pl-20 pt-3">
            An SMS message will be sent back to you containing the Virtual NIN
            generated for you.
          </li>
        </ul>
        <div className="pt-5 text-2xl flex">
          <svg width="40" height="40" fill="none">
            <g
              stroke="#2C3E50"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.75"
              clip-path="url(#apphint_svg__a)"
            >
              <path d="M26.666 6.667H13.333c-.92 0-1.667.746-1.667 1.666v23.334c0 .92.747 1.666 1.667 1.666h13.333c.921 0 1.667-.746 1.667-1.666V8.333c0-.92-.746-1.666-1.666-1.666zm-8.332 1.666h3.333m-1.667 20v.017"></path>
            </g>
            <defs>
              <clipPath id="apphint_svg__a">
                <path fill="#fff" d="M0 0h40v40H0z"></path>
              </clipPath>
            </defs>
          </svg>{" "}
          <h4>Through the NIMC app</h4>
        </div>
        <ul className="pt-5">
          <li className="pl-20 pt-3">
            Download the NIMC App- Click on "Get Virtual NIN"
          </li>
          <li className="pl-20 pt-3">
            Select "Input Enterprise short-code" and type 931095
          </li>
          <li className="pl-20 pt-3">
            Click "Submit" and your virtual NIN will be generated.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LeftPart;
