import { createContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(
    JSON.parse(localStorage.getItem("newauth")) || []
  );

  useEffect(() => {
    localStorage.setItem("newauth", JSON.stringify(auth));
  }, [auth]);

  const navigate = useNavigate();
  const location = useLocation();

  const [au, setAu] = useState(null);
  const [uu, setUu] = useState(null);
  const [uw, setUw] = useState(null);
  const [tau, settAu] = useState(null);
  const [tuu, settUu] = useState(null);
  const [tav, settAv] = useState(null);
  const [tuv, settuv] = useState(null);
  const [tcu, settcu] = useState(null);
  const [tvoucher, setTvoucher] = useState(null);
  const [uvoucher, setUvoucher] = useState(null);

  const [datas2, setDatas2] = useState([]);

  const all = {
    au: "",
    uu: "",
    uw: "",
    tau: "",
    tuu: "",
    tav: "",
    tuv: "",
    tcu: "",
    tvoucher: "",
    uvoucher: "",
  };

  const logout = async () => {
    // if used in more components, this should be in context
    // axios to /logout endpoint
    setAuth({});
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        datas2,
        setDatas2,
        auth,
        setAuth,
        navigate,
        location,
        logout,
        au,
        setAu,
        uu,
        setUu,
        all,
        uw,
        setUw,
        tau,
        tuu,
        tav,
        tuv,
        settAu,
        settUu,
        settAv,
        settuv,
        tcu,
        settcu,
        tvoucher,
        setTvoucher,
        uvoucher,
        setUvoucher,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
