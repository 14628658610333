import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BellIcon,
  ChipIcon,
  InboxIcon,
  SearchIcon,
  UserCircleIcon,
  XIcon,
  UserIcon,
  LogoutIcon,
} from "@heroicons/react/outline";
import useAuth from "../../hooks/useAuth";

const Header = () => {
  const { auth, setAuth } = useAuth();
  const [menuVisible, setMenuVisible] = useState(false);

  const handleLogout = () => {
    setAuth(null);
    localStorage.removeItem("newauth");
  };

  const handleMenuOpen = () => {
    setMenuVisible(true);
  };

  const handleMenuClose = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        handleLogout();
      }, 15 * 60 * 1000); // 15 minutes in milliseconds
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    const handleWindowClose = () => {
      handleLogout();
    };

    // Attach event listeners for user activity and window close
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    window.addEventListener("beforeunload", handleWindowClose);

    // Start the initial timer
    resetTimer();

    // Clean up event listeners on component unmount
    return () => {
      clearTimeout(logoutTimer);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, []);

  return (
    <div className="w-full py-6 bg-[#F0F5F5] items-center justify-between flex px-12">
      {/* search */}
      <div className="w-full lg:flex hidden space-x-4 items-center justify-start py-2">
        <SearchIcon className="w-4 h-4" />
        <input
          type="text"
          placeholder="Search anything..."
          className="bg-transparent outline-none"
        />
      </div>
      {/* logo */}
      <div className="items-center w-full justify-center flex space-x-4">
        <img src="logo.png" style={{ width: "auto", height: "40px" }} />
      </div>
      {/* icons */}
      <div className="items-center justify-end space-x-6 flex w-full">
        <div className="relative">
          <div
            className="flex items-center cursor-pointer"
            onClick={handleMenuOpen}
          >
            <UserCircleIcon className="header-icon" />
            <span className="hidden lg:inline-block ml-2">
              {auth?.nuser?.firstName}
            </span>
          </div>
          {menuVisible && (
            <div className="absolute bg-white shadow-lg rounded-md py-2 mt-2 w-48 right-0">
              <button
                onClick={handleLogout}
                className="flex items-center block px-4 py-2 text-gray-800 hover:bg-gray-100"
              >
                <LogoutIcon className="w-4 h-4 mr-2" />
                Logout
              </button>
              <div
                className="absolute top-0 right-2 mt-2 cursor-pointer z-10"
                onClick={handleMenuClose}
              >
                <XIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          )}
        </div>
        <button className="header-icon" onClick={handleLogout}>
          <LogoutIcon />
        </button>
      </div>
    </div>
  );
};

export default Header;
