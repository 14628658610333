import React, { useState } from "react";
import api from "../api/axios";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Signup = () => {
  const today = new Date();
  const year = today.getFullYear();

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMsg("");
    setIsLoading(true); // Set isLoading to true when form is submitted
    try {
      const url = "/register";
      const { data: res } = await api.post(url, data);
      setMsg(res.message);
      const result = await api.post("/unit", {
        email: data.email,
        au: 0,
        uu: 0,
        tu: 0,
      });
      //console.log(result);
      setData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      });
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    } finally {
      setIsLoading(false); // Set isLoading back to false after response is received
    }
  };

  return (
    <section className="flex flex-col md:flex-row h-screen items-center">
      <div className="bg-blue-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
        <img
          src="background.png"
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
        <div className="w-full h-100">
          <h1 className="text-xl font-bold">
            <img src="logo.png" width="200" height="100" />
          </h1>
          <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
            Create an account
          </h1>

          <form className="mt-1" onSubmit={handleSubmit}>
            <div>
              <label className="block text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                onChange={handleChange}
                value={data.firstName}
                placeholder="Enter First Name"
                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                autoFocus
                required
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700">Last Name</label>
              <input
                type="text"
                placeholder="Enter Last Name"
                name="lastName"
                onChange={handleChange}
                value={data.lastName}
                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                required
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700">Email Address</label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                value={data.email}
                placeholder="Enter Email Address"
                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                required
              />
            </div>

            <div className="mt-4">
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                value={data.password}
                placeholder="Enter Password"
                minLength="6"
                className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                required
              />
            </div>
            {error && (
              <div className={"w-full px-2 " + styles.error_msg}>{error}</div>
            )}
            {msg && (
              <div className={"w-full px-2 " + styles.success_msg}>{msg}</div>
            )}
            {isLoading ? ( // Conditionally render loading indicator
              <button
                disabled
                className="w-full block bg-blue-500 text-white font-semibold rounded-lg px-4 py-3 mt-6 cursor-wait"
              >
                Creating Account...
              </button>
            ) : (
              <button
                type="submit"
                className="w-full block bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
              >
                Create Account
              </button>
            )}
          </form>

          <hr className="my-6 border-gray-300 w-full" />

          <p className="mt-8">
            Already have an account?
            <Link
              to="/login"
              className="text-blue-500 hover:text-blue-700 font-semibold"
            >
              Login
            </Link>
          </p>

          <p className="text-sm text-gray-500 mt-12">
            &copy; {year} Ficoven - All Rights Reserved.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Signup;
