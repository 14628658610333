import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from "jquery";
import "datatables.net";

function LeftPart() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const email = auth?.nuser?.email;
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState([]);
  const roles = auth?.roles;
  const hasSuperAdminRole = roles.includes(3007);

  useEffect(() => {
    if (hasSuperAdminRole) {
      axiosPrivate
        .get("/verify")
        .then((response) => {
          const dataArray = Object.values(response.data);
          if (Array.isArray(dataArray)) {
            const formattedData = dataArray.map((item) => ({
              ...item,
              datefield: new Date(item.datefield).toLocaleDateString(),
              timefield: new Date(item.datefield).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }),
            }));
            setDatas(formattedData);
          } else {
            console.log("Response data is not an array");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axiosPrivate
        .get(`/verify/${email}`)
        .then((response) => {
          const dataArray = Object.values(response.data);
          if (Array.isArray(dataArray)) {
            const formattedData = dataArray.map((item) => ({
              ...item,
              datefield: new Date(item.datefield).toLocaleDateString(),
              timefield: new Date(item.datefield).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }),
            }));
            setDatas(formattedData);
          } else {
            console.log("Response data is not an array");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      $("#user-table").DataTable();
    }
  }, [isLoading]);

  const columns = [
    { label: "First name", field: "firstname" },
    { label: "Last name", field: "lastname" },
    { label: "Phone", field: "phone" },
    { label: "Identity Number", field: "idno" },
    { label: "Status", field: "success" },
    { label: "Remark", field: "remark" },
    { label: "Date", field: "datefield" },
    { label: "Time", field: "timefield" },
  ];

  return (
    <div className=" col-span-12 border-r border-gray-200 items-start justify-start flex flex-col w-full ">
      {/* top section*/}
      <div className="w-full items-start justify-start flex flex-col px-12 pt-12 pb-6">
        <h1 className="font-bold text-xl xl:text-2xl pb-2 ">
          {" "}
          View Verifications{" "}
        </h1>
        <p className="text-md text-gray-800">
          Find below are informations you verified
        </p>
        <div className="w-full mt-4">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="table-wrapper">
              <table id="user-table" className="display w-full">
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <th key={column.field}>{column.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {datas.map((data) => (
                    <tr key={data._id}>
                      {columns.map((column) => (
                        <td key={`${data._id}-${column.field}`}>
                          {column.field === "success"
                            ? data[column.field].toString().toLowerCase() ===
                              "true"
                              ? "Successful"
                              : "Failed"
                            : column.render
                            ? column.render(data)
                            : data[column.field]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {/* bottom section*/}
    </div>
  );
}

export default LeftPart;
