import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51M03yaE77BLP2l34f9sVHOrJzJd24UXXvI0z43ZpXRO8XnkhM7hgK6iGodr5g3gTsf2EBxokoEJcne5XksHprVng0077inhXIH"
);

function LeftPart() {
  const { auth, setAu, setUu, setUw, all, uw, datas2, setDatas2 } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [error2, setError2] = useState("");
  const [msg2, setMsg2] = useState("");
  const email = auth?.nuser?.email;
  const [isLoading, setIsLoading] = useState(false);
  const [totalAmount2, setTotalAmount2] = useState(0);
  const [data, setData] = useState({
    email: email,
    amount: "",
  });

  const unitOptions = [
    { label: "Choose one", value: "" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "20", value: "20" },
    { label: "30", value: "30" },
    { label: "40", value: "40" },
    { label: "50", value: "50" },
    { label: "60", value: "60" },
    { label: "70", value: "70" },
    { label: "80", value: "80" },
    { label: "90", value: "90" },
    { label: "100", value: "100" },
    { label: "500", value: "500" },
    { label: "1000", value: "1000" },
  ];

  const stripe = useStripe();
  const elements = useElements();

  const handleChange2 = ({ currentTarget: input }) => {
    const selectedAmount = parseInt(input.value);
    setData({ ...data, [input.name]: selectedAmount });

    setTotalAmount2(selectedAmount * 10);
  };

  const handleSubmit2 = async (event) => {
    event.preventDefault();
    setError2("");

    try {
      setIsLoading(true);

      const response = await axiosPrivate.post("/create-payment-intent", {
        amount: totalAmount2 * 100,
      });

      const { client_secret } = response.data;

      const result = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: email,
          },
        },
      });

      if (result.error) {
        setError2(result.error.message);
      } else {
        const url = "/voucher";
        const { data: res } = await axiosPrivate.post(url, data);
        setMsg2("Purchase successful");
      }
    } catch (error) {
      setError2(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (uw !== null) {
      all.uw = uw;
    }
  }, [uw]);

  return (
    <div className="col-span-2 border-r border-gray-200 items-start justify-start flex flex-col w-full ">
      <div className="w-full items-start justify-start flex flex-col px-12 pt-12 pb-6">
        <h1 className="font-bold text-xl xl:text-2xl pb-2 pt-5"> Vouchers </h1>
        <p className="text-md text-gray-800">
          Purchase vouchers with the form below
        </p>
        <form className="space-y-6 w-full mt-4" onSubmit={handleSubmit2}>
          <div>
            <label className="block">
              <span className="text-gray-700 text-lg font-bold">
                Voucher Amount
              </span>
              <select
                name="amount"
                id="num"
                onChange={handleChange2}
                value={data.unit}
                className="mt-1 h-12 block w-full pl-4 border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                required
              >
                {unitOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          {totalAmount2 > 0 && (
            <div className="mt-4 text-xl font-bold">
              Total Amount: ${totalAmount2.toLocaleString()}
            </div>
          )}
          <div>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>
          <div>
            {error2 && (
              <div className={"w-full px-2 " + styles.error_msg}>{error2}</div>
            )}
            {msg2 && (
              <div className={"w-full px-2 " + styles.success_msg}>{msg2}</div>
            )}
          </div>
          <div className="flex items-center">
            {isLoading ? (
              <button
                disabled
                className="h-12 px-6 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none flex-1 mr-1"
              >
                Purchasing ...
              </button>
            ) : (
              <button
                type="submit"
                className="h-12 px-6 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none flex-1 mr-1"
              >
                Purchase with Stripe
              </button>
            )}
          </div>
          <div className="text-gray-700 text-lg font-bold">
            1 Voucher = 1 Verification
          </div>
        </form>
      </div>
    </div>
  );
}

export default function LeftPartWithStripe() {
  return (
    <Elements stripe={stripePromise}>
      <LeftPart />
    </Elements>
  );
}
